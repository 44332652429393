<template>
  <div class="d-flex flex-column" :style="{ 'background-color': '#eee', 'min-height': '100vh' }">
    <header class="sticky-top pb-2" style="background-color: #eee;">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 px-0" style="background-color: #eee;">
            <gl-card class="border-bottom-1" style="margin-bottom: -8px; border-radius: 0%;">
              <div class="row align-items-center">
                <div class="col-auto">
                  <img
                    src="https://play-lh.googleusercontent.com/CgtHFkL-KeiIfo6LUy-lHavAZi2ZgwQcZ_VXUKsELXyBZha2RCqGBPcv4cj8s1Z6pLn0=w40-h40-rw"
                    alt="Logo" width="100%">
                </div>
                <div class="col text-truncate">
                  <h5 style="margin-left: 10px;">{{ title.length > 30 ? title.substring(0, 30) + '...' : title }}</h5>
                </div>
                <div class="col-auto mt-2 text-right">
                  <p id="countdown-timer"></p>
                </div>
              </div>
            </gl-card>
          </div>
        </div>
      </div>
    </header>
    <div class="container-fluid flex-grow-1">
      <div class="row align-items-center justify-content-center" style="height: 100%;">
        <div class="col-12 px-0">
          <div class="row justify-content-center"
            style="max-height: calc(100vh - 130px); overflow-y: auto; padding-bottom: 20px;">
            <div class="col-12">
              <div v-for="(item, index) in items" :key="index">
                <gl-card :class="{ 'border-top-1': index === 0, 'border-top-0': index !== 0 }" style="border-radius: 0%">
                  <div style="display: flex; align-items: center;">
                    <div class="col-2">
                      <span style="font-size: 18px">{{ index + 1 }}</span>
                    </div>
                    <div class="col-10">
                      <div class="row justify-content-center">
                        <gl-button-group>
                          <gl-button v-for="letter in ['A', 'B', 'C', 'D', 'E']"
                            :class="{ 'mx-1': true, 'btn-info': item.selection === letter }"
                            style="border-radius: 50%; height: 50px; width: 50px; font-size: 20px;" :key="letter + index"
                            @click="handleSelection(index, letter)">
                            {{ letter }}
                          </gl-button>
                        </gl-button-group>
                      </div>
                    </div>
                  </div>
                </gl-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="pt-2 pb-0" style="background-color: #eee; position: fixed; bottom: 0; width: 100%;">
      <div class="container-fluid" style="background-color: red;">
        <div class="row justify-content-center">
          <div class="col-12 px-0" style="background-color: #eee;">
            <gl-card class="border-top-1" style="margin-top: -10px; border-radius: 0%;">
              <div class="text-center">
                <gl-button variant="info" style="height: 40px; width: 400px;" @click="submitAnswer">Submit</gl-button>
              </div>
            </gl-card>
          </div>
        </div>
      </div>
    </footer>
    <gl-modal v-if="showModal" modalId="test-modal-id" title="Example title" :actionPrimary='{ "text": "Okay" }'
      :actionSecondary='{ "text": "Discard Changes" }' :actionCancel='{ "text": "Cancel" }' :noFocusOnShow="false"
      header-bg-variant="" header-border-variant="" header-text-variant="" body-bg-variant="" body-text-variant=""
      footer-bg-variant="" footer-border-variant="" footer-text-variant="" :scrollable="false" no-fade="">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p>
    </gl-modal>
  </div>
</template>
<script>
import { GlCard, GlButtonGroup, GlButton, GlModal } from '@gitlab/ui';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

export default {
  name: 'EmptyState',
  components: {
    GlCard,
    GlButtonGroup,
    GlButton,
    GlModal
  },
  data() {
    return {
      userId: 0,
      title: "",
      duration: 0,
      questionCount: 0,
      items: [],
      selections: [],
      timerInterval: null,
      examId: "",
      remainingTime: "",
      showModal: false
    };
  },
  mounted() {
    const user = jwtDecode(localStorage.getItem('token'))
    this.userId = user.user_id

    this.examId = this.$route.query.id;
    if (!this.examId) {
      this.$router.push('/start');
    }

    const token = localStorage.getItem('token');
    axios.get(`https://api.eurekaedutech.com/v1/ljd/${this.examId}`, {
      headers: {
        token: token
      }
    })
      .then(response => {
        this.title = response.data.data.name
        this.duration = response.data.data.duration
      })
      .catch(error => {
        if (error.response.data.code == 404) {
          this.$router.push('/start');
        }
      }).then(() => {
        // Todo: enhance code qualiity
        this.loadSavedSelections();
        let savedTime = localStorage.getItem(`countdown-timer-${this.examId}`);

        // time remaining logic
        if (savedTime == null) {
          const currentDate = new Date();
          const deadline = new Date(currentDate.getTime() + this.duration * 60000); // add 10 minutes in milliseconds
          const remainingTime = deadline - currentDate
          const remainingTimeInSeconds = Math.floor(remainingTime / 1000);
          const remainingMinutes = Math.floor(remainingTimeInSeconds / 60);
          const remainingSeconds = remainingTimeInSeconds % 60;

          let newTimerValue = remainingMinutes.toString().padStart(2, "0") + ":" + remainingSeconds.toString().padStart(2, "0");
          savedTime = newTimerValue

          if (this.examId != undefined) {
            localStorage.setItem(`deadline-exam-${this.examId}`, deadline.toString());
          }
        }

        if (savedTime && this.$route.path == "/exam") {
          document.getElementById('countdown-timer').innerHTML = savedTime;
        }
        this.timerInterval = setInterval(this.countdown, 1000);
      });

    axios.get(`https://api.eurekaedutech.com/v1/ljd/${this.examId}/questions`, {
      headers: {
        token: token
      }
    })
      .then(response => {
        this.questionCount = response.data.data.length
        this.items = Array.from({ length: this.questionCount }, (_, id) => ({ message: `Question ${id + 1}` }))
        this.selections = new Array(this.questionCount).fill('-')
      })
      .catch(error => {
        console.log(error);
      });
  },
  beforeRouteLeave(to, from, next) {
    // check if countdown instance exists before stopping it
    clearInterval(this.timerInterval);
    clearInterval(this.countdown)
    next();
  },
  methods: {
    countdown() {
      if (this.$route.path != "/exam") {
        return
      }

      // TODO: remove this later
      let timerElement = document.getElementById("countdown-timer");
      let timeLeft = timerElement.innerHTML.split(":");
      let minutes = parseInt(timeLeft[0]);
      let seconds = parseInt(timeLeft[1]);

      const currentDate = new Date();
      const deadline = new Date(localStorage.getItem(`deadline-exam-${this.examId}`))
      const remainingTime = deadline - currentDate
      const remainingTimeInSeconds = Math.floor(remainingTime / 1000);
      const remainingMinutes = Math.floor(remainingTimeInSeconds / 60);
      const remainingSeconds = remainingTimeInSeconds % 60;

      minutes = remainingMinutes
      seconds = remainingSeconds

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.timerId);
          if (!this.alertShown) {
            localStorage.removeItem(`selections-${this.examId}`)
            localStorage.removeItem(`countdown-timer-${this.examId}`)
            localStorage.removeItem(`deadline-exam-${this.examId}`)
            this.alertShown = true;
            alert("Exam has ended!");

            let localAnswers = localStorage.getItem(`selections-${this.examId}`)
            let answer = new Array(this.questionCount).fill('-')

            if (localAnswers == null) {
              localAnswers = JSON.stringify(answer)
            }

            const token = localStorage.getItem('token');
            axios.post(`https://api.eurekaedutech.com/v1/ljd/${this.examId}/answers`, {
              user_id: this.userId,
              ljd_id: this.examId,
              total_question: this.questionCount,
              answers: localAnswers
            }, {
              headers: {
                token: token
              }
            })
              .then(response => {
                console.log(response)
              })
              .catch(error => {
                console.error(error);
              }).finally(() => {
                this.$router.push(`/results?id=${this.examId}`);
              });
          }
          return;
        } else {
          minutes--;
          seconds = 59;
        }
      } else {
        seconds--;
      }

      if (isNaN(minutes)) {
        minutes = 0
      }

      if (isNaN(seconds)) {
        seconds = 0
      }

      let newTimerValue = minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
      timerElement.innerHTML = newTimerValue;
      this.countdownValue = newTimerValue;

      // Save countdown timer value to localStorage
      if (this.examId != undefined) {
        localStorage.setItem(`countdown-timer-${this.examId}`, newTimerValue);
      }
    },
    loadSavedSelections() {
      const savedSelections = localStorage.getItem(`selections-${this.examId}`);
      if (savedSelections) {
        this.selections = JSON.parse(savedSelections);
        this.items.forEach((item, index) => {
          item.selection = this.selections[index];
          this.handleSelection(index, item.selection);
        });
      }
    },
    handleSelection(index, letter) {
      const items = [...this.items];
      items[index].selection = letter;
      this.items = items;

      const selections = [...this.selections];
      selections[index] = letter
      this.selections = selections;

      if (this.examId != undefined) {
        localStorage.setItem(`selections-${this.examId}`, JSON.stringify(selections));
      }
    },
    redirectToExamResult() {
      this.$router.push(`/results?id=${this.examId}`);
    },
    submitAnswer() {
      let localAnswers = localStorage.getItem(`selections-${this.examId}`)
      let answer = new Array(this.questionCount).fill('-')

      if (localAnswers == null) {
        localAnswers = JSON.stringify(answer)
      }

      const confirmation = confirm("Apakah kamu yakin untuk menyelesaikan ujian?")
      if (!confirmation) {
        return
      }

      const token = localStorage.getItem('token');
      axios.post(`https://api.eurekaedutech.com/v1/ljd/${this.examId}/answers`, {
        user_id: this.userId,
        ljd_id: this.examId,
        total_question: this.questionCount,
        answers: localAnswers
      }, {
        headers: {
          token: token
        }
      })
        .then(response => {
          console.log(response)
          localStorage.removeItem(`selections-${this.examId}`)
          localStorage.removeItem(`countdown-timer-${this.examId}`)
          localStorage.removeItem(`deadline-exam-${this.examId}`)
          this.$router.push(`/results?id=${this.examId}`);
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
};
</script>
<style scoped>
body {
  background-color: #eeeeee;
}

header {
  text-align: center;
}

h3 {
  font-size: 1.5rem;
}

.container-fluid {
  max-width: 480px;
  ;
}

.col-lg-6 {
  max-width: 480px;
  ;
}

.gl-button {
  /* font-size: 14px; */
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 768px) {
  h3 {
    font-size: 1.2rem;
  }

  .col-lg-6 {
    max-width: 100%;
  }

  .gl-button {
    /* font-size: 12px; */
    height: 30px;
    width: 30px;
  }
}

@media only screen and (max-width: 576px) {
  h3 {
    font-size: 1rem;
  }

  .col-md-8 {
    max-width: 100%;
  }

  .gl-button {
    /* font-size: 10px; */
    height: 25px;
    width: 25px;
  }
}

footer .col-auto {
  max-width: 100%;
}

footer .gl-button {
  width: 100%;
  max-width: 300px;
}
</style>