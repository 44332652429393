<template>
  <div class="container" style="background-color: #fff; max-width: 478px;">
    <div class="row align-items-center" style="height: 95vh;">
      <div class="mx-auto col-11">
        <div class="row mb-3">
          <div class="col">
            <p style="font-size: 16px;">Silahkan masukan code untuk memulai ujian.</p>
          </div>
        </div>
        <gl-form @submit.prevent="redeemCode">
          <div>
            <gl-form-group id="input-group-1" label="Exam Code" label-for="exam-code">
              <gl-form-input type="text" v-model="code" placeholder="Masukan exam code" required></gl-form-input>
            </gl-form-group>
          </div>
          <div class="row">
            <div class="col">
              <gl-button category="primary" variant="info" size="medium" type="submit">
                Submit
              </gl-button>
            </div>
          </div>
        </gl-form>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="footer-links">
        <a href="/start">Beranda</a>
        <a href="https://www.eurekaedutech.com/about" target="_blank">Tentang</a>
        <a href="https://www.eurekaedutech.com/contact" target="_blank">Kontak</a>
        <a href="https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/tryout/guides-eureka-tryout.pdf"
          target="_blank">Panduan</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GlForm, GlFormGroup, GlFormInput, GlButton } from "@gitlab/ui";
import axios from 'axios';

export default {
  name: "EmptyState",
  components: {
    GlForm,
    GlFormGroup,
    GlFormInput,
    GlButton,
  },
  methods: {
    redirectToExam() {
      this.$router.push('/exam');
    },
    redeemCode() {
      const token = localStorage.getItem('token');
      axios.post(`https://api.eurekaedutech.com/v1/ljd/claim`, {
        code: this.code,
      }, {
        headers: {
          token: token,
        },
      })
        .then(response => {
          console.log(response)
          this.$router.push(`/exam?id=${response.data.data.exam_id}`);
        })
        .catch(error => {
          alert("Tryout tidak ditemukan")
          // handle error response here
          console.error(error);
        });
    },
  },
  data() {
    return {
      code: '',
    };
  },
};
</script>

<style>
body {
  background-color: #eeeeee;
}
</style>
