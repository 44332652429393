import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './Login.vue'
import Register from './Register.vue'
import Exam from './Exam.vue';
import Start from './Start.vue';
import ExamResult from './ExamResult.vue';

Vue.config.productionTip = false;
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/start',
    component: Start,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/exam',
    component: Exam,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/results',
    component: ExamResult,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    redirect: '/start'
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.meta.requiresAuth && !token) {
    next('/');
  } else if (to.path === '/' && token) {
    next('/start');
  } else {
    next();
  }
});

new Vue({
  router,
  render: h => h('router-view'),
}).$mount('#app');
