<template>
  <div class="container" style="background-color: #fff; max-width: 478px;;">
    <div class="row align-items-center" style="height: 95vh;">
      <div class="mx-auto col-11">
        <div class="col d-flex justify-content-center">
          <div class="row">
            <img
              src="https://play-lh.googleusercontent.com/CgtHFkL-KeiIfo6LUy-lHavAZi2ZgwQcZ_VXUKsELXyBZha2RCqGBPcv4cj8s1Z6pLn0=w240-h480-rw"
              class="rounded" width="60" height="60">
          </div>
        </div>
        <div class="col d-flex justify-content-center mb-4">
          <div class="row">
            <span class="h5" style="font-size: 110%">Eureka Tryout</span>
          </div>
        </div>

        <gl-form @submit.prevent="registerUser">
          <div>
            <gl-form-group id="input-group-1" label="Email" label-for="input-1">
              <gl-form-input type="email" v-model="email" placeholder="Masukan email" required></gl-form-input>
            </gl-form-group>
          </div>
          <div>
            <gl-form-group id="input-group-1" label="Password" label-for="input-1">
              <gl-form-input type="password" v-model="password" placeholder="Masukan password" required></gl-form-input>
            </gl-form-group>
          </div>
          <div class="row mb-3">
            <div class="col d-flex justify-content-start">
              <gl-form-checkbox v-model="rememberMe" value="accepted">
                Remember me?
              </gl-form-checkbox>
            </div>
            <!-- disable for a while -->
            <!-- <div class="col d-flex justify-content-end">
            <a href="#">Forgot password?</a>
            </div> -->
          </div>
          <div class="row mb-4">
            <div class="col">
              <gl-button type="submit" category="primary" variant="info" size="medium" block>
                Login
              </gl-button>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex justify-content-center">
              <p>Belum punya akun? <a href="/register">Register</a></p>
            </div>
          </div>
        </gl-form>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="footer-links">
        <a href="/start">Beranda</a>
        <a href="https://www.eurekaedutech.com/about" target="_blank">Tentang</a>
        <a href="https://www.eurekaedutech.com/contact" target="_blank">Kontak</a>
        <a href="https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/tryout/guides-eureka-tryout.pdf"
          target="_blank">Panduan</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GlForm, GlFormGroup, GlFormInput, GlButton, GlFormCheckbox } from "@gitlab/ui";
import axios from 'axios';

export default {
  name: "EmptyState",
  components: {
    GlForm,
    GlFormGroup,
    GlFormInput,
    GlButton,
    GlFormCheckbox
  },
  methods: {
    redirectToStart() {
      this.$router.push('/start');
    },
    registerUser() {
      axios.post('https://api.eurekaedutech.com/v1/ljd/login', {
        email: this.email,
        password: this.password,
      })
        .then(response => {
          console.log(response)
          localStorage.setItem('token', response.data.data.token);
          this.$router.push('/start');
        })
        .catch(error => {
          alert("Email atau password salah")
          // handle error response here
          console.error(error);
        });
    },
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
    };
  },
};
</script>

<style>
body {
  background-color: #eeeeee;
}
</style>