<template>
  <div class="container" style="background-color: #fff; max-width: 478px;;">
    <div class="row align-items-center" style="height: 95vh;">
      <div class="mx-auto col-11">
        <div class="row">
          <div class="col text-center">
            <b style="font-size: 16px;">{{ title }}</b>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col text-center">
            <p style="font-size: 15px;">Selamat kamu telah menyelesaikan Try Out UTBK-SNBT! <br> Nilai akan kamu terima
              maksimal H+1 melalui email, ya.</p>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <gl-button class="mx-2" variant="info" size="medium" @click="redirectToHome">
            Lanjut
          </gl-button>
          <!-- <div class="col"> -->
          <!-- <gl-button-group> -->
          <!-- <gl-button class="mx-2" variant="info" size="medium" @click="redirectToExam">
            Review
          </gl-button>
          <gl-button class="mx-2" variant="default" size="medium" @click="redirectToHome">
            Lanjut
          </gl-button> -->
          <!-- </gl-button-group> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="footer-links">
        <a href="/start">Beranda</a>
        <a href="https://www.eurekaedutech.com/about" target="_blank">Tentang</a>
        <a href="https://www.eurekaedutech.com/contact" target="_blank">Kontak</a>
        <a href="https://eureka-edutech.s3.ap-southeast-1.amazonaws.com/public/tryout/guides-eureka-tryout.pdf"
          target="_blank">Panduan</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GlButton } from '@gitlab/ui';
import axios from 'axios';

export default {
  name: "EmptyState",
  components: {
    GlButton,
    // GlButtonGroup
  },
  methods: {
    redirectToExam() {
      this.$router.push('/exam');
    },
    redirectToHome() {
      this.$router.push('/start');
    }
  },
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    this.examId = this.$route.query.id;
    if (!this.examId) {
      this.$router.push('/start');
    }

    const token = localStorage.getItem('token');
    axios.get(`https://api.eurekaedutech.com/v1/ljd/${this.examId}`, {
      headers: {
        token: token
      }
    })
      .then(response => {
        this.title = response.data.data.name
      })
      .catch(error => {
        if (error.response.data.code == 404) {
          this.$router.push('/start');
        }
      });
  }
};
</script>

<style>
body {
  background-color: #eeeeee;
}
</style>